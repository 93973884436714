import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

//// 解决router BUG 当前显示的路由再次点击会打印错误的问题
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

export default new Router({
    routes: [
        {
            path: '/',
            // redirect: '/home',
            name: 'home',
            component: resolve => require(['../components/Homepage.vue'], resolve),
            meta:{
                title:'UGOAI | 城途智能科技（上海）有限公司'
            }
        },
        {
            path: '/home',
            redirect: '/',
        },
        {
            path: '/solutions',
            name: 'solutions',
            component: resolve => require(['../components/solutionsPage/solutions.vue'], resolve),
            meta:{
                title:'解决方案 | 城途智能'
            }
        },
        {
            path: '/intelligentParking',
            name: 'intelligentParking',
            component: resolve => require(['../components/solutionsPage/intelligentParking.vue'], resolve),
            meta:{
                title:'智慧泊车 | 城途智能'
            }
        },
        {
            path: '/vehicle-roadCooperation',
            name: 'vehicle-roadCooperation',
            component: resolve => require(['../components/solutionsPage/vehicle-roadCooperation.vue'], resolve),
            meta:{
                title:'车路协同 | 城途智能'
            }
        },
        {
            path: '/urbanTransportation',
            name: 'urbanTransportation',
            component: resolve => require(['../components/solutionsPage/urbanTransportation.vue'], resolve),
            meta:{
                title:'城市交通 | 城途智能'
            }
        },
        {
            path: '/intelligentDrive',
            name: 'intelligentDrive',
            component: resolve => require(['../components/solutionsPage/intelligentDrive.vue'], resolve),
            meta:{
                title:'智能驾驶 | 城途智能'
            }
        },
        {
            path: '/products/intelligentRoad',
            name: 'intelligentRoad',
            component: resolve => require(['../components/productCenterPage/intelligentRoad.vue'], resolve),
            meta:{
                title:'智慧的路 | 城途智能'
            }
        },
        {
            path: '/products/intelligentField',
            name: 'intelligentField',
            component: resolve => require(['../components/productCenterPage/intelligentField.vue'], resolve),
            meta:{
                title:'智慧的场 | 城途智能'
            }
        },
        {
            path: '/products/intelligentCloud',
            name: 'intelligentCloud',
            component: resolve => require(['../components/productCenterPage/intelligentCloud.vue'], resolve),
            meta:{
                title:'智慧的云 | 城途智能'
            }
        },
        {
            path: '/products/intelligentGraph',
            name: 'intelligentGraph',
            component: resolve => require(['../components/productCenterPage/intelligentGraph.vue'], resolve),
            meta:{
                title:'智慧的图 | 城途智能'
            }
        },
        {
            path: '/contact-us',
            name: 'contactUs',
            component: resolve => require(['../components/contactUs.vue'], resolve),
            meta:{
                title:'业务联系 | 城途智能'
            }
        },
        {
            path: '/about-us',
            name: 'aboutUs',
            component: resolve => require(['../components/aboutUsPage/aboutUs.vue'], resolve),
            meta:{
                title:'关于我们 | 城途智能'
            }
        },
        {
            path: '/news-room',
            name: 'newsRoom',
            component: resolve => require(['../components/aboutUsPage/newsRoom.vue'], resolve),
            meta:{
                title:'新闻动态 | 城途智能'
            }
        },
        {
            path: '/news-page',
            name: 'newsPage',
            component: resolve => require(['../components/aboutUsPage/newsPage.vue'], resolve),
            meta:{
                title:'新闻动态 | 城途智能'
            }
        },
        {
            path: '/map-customisation/login',
            name: 'mapCustomisation-login',
            component: resolve => require(['../components/mapCustomisation/login.vue'], resolve),
            meta:{
                title:'登录 | 城途智能'
            }
        },
        {
            path: '/map-customisation/upload',
            name: 'mapCustomisation-upload',
            component: resolve => require(['../components/mapCustomisation/upload.vue'], resolve),
            meta:{
                title:'上传地图 | 城途智能'
            }
        },
        {
            path: '/map-customisation',
            name: 'mapCustomisation',
            component: resolve => require(['../components/mapCustomisation/mapConfiguration.vue'], resolve),
            meta:{
                title:'自定义地图 | 城途智能'
            }
        },
    ],
    mode: 'history'
});
