import Vue from 'vue'
import App from './App.vue'

import router from './router'
import axios from 'axios'

// bootstrap-vue
import BootstrapVue from "bootstrap-vue";

Vue.use(BootstrapVue);

// bootstrap-vue css
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

import { Carousel, CarouselItem, Backtop, Icon, Tabs, TabPane, Upload, Select, Option, Slider, ColorPicker } from 'element-ui';
Vue.use(Carousel);            // 注册全局组件Carousel(走马灯--在有限空间内，循环播放同一类型的图片、文字等内容)
Vue.use(CarouselItem);        // 注册全局组件CarouselItem
Vue.use(Backtop);             // 注册全局组件Backtop(回到顶部)
Vue.use(Icon);
Vue.use(Upload)               //注册全局组件Upload(上传文件)
Vue.use(Select)
Vue.use(Option)
Vue.use(Slider)
Vue.use(ColorPicker)
Vue.component(Tabs.name, Tabs); // 注册全局组件Tabs(选项卡功能)
Vue.component(TabPane.name, TabPane); // 注册全局组件Tabs(选项卡功能)

Vue.prototype.$axios = axios


router.beforeEach((to, from, next) => {
  if (to.meta.title) {
      document.title = to.meta.title
  } else {
      document.title = '主页 | UGOAI' //此处写默认的title
  }
  next()
}),
router.afterEach((to,from,next)=>{
   window.scrollTo(0,0)
})

new Vue({
  render: h => h(App),
  router,
  mounted(){
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')

